import React, { Component } from "react";
import { Input, Modal, Button, Form, Tooltip, Popover, Select, Checkbox, Space, Collapse } from 'antd';
import { PlusSquareOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import MyDiagramViewer from "../diagramView/MyDiagramViewer";
import { generateID } from "../../utils/generateID";
const { Panel } = Collapse;
const { TextArea } = Input;
class CallActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: null,
            visible: false,
            method: null,
            event: null,
            processList: []
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeElement && this.props.activeElement.type === 'bpmn:CallActivity' && !this.state.visible) {
            let arr = Object.entries(this.props.activeElement.businessObject);
            let value = {
                InMappings: [],
                OutMappings: []
            }
            arr.forEach((el) => {
                if (el[0] === 'documentation') {
                    value[el[0]] = el[1][0] && el[1][0].text ? el[1][0].text : '';
                }
                else {
                    value[el[0]] = el[1];
                }

            });
            if (value.extensionElements) {
                value.extensionElements.values.forEach((el) => {
                    if (el.inputParameters) {
                        value.InputParameters = el.inputParameters;
                    }
                    if (el.outputParameters) {
                        value.OutputParameters = el.outputParameters;
                    }
                    if (el.$type === 'camunda:In') {
                        if (el.variables) {
                            value.inMappingAll = true;
                        }
                        else {
                            if (el.source) {
                                value.InMappings.push({ type: 'source', source: el.source, target: el.target })
                            }
                            else {
                                value.InMappings.push({ type: 'sourceExpression', source: el.sourceExpression, target: el.target })
                            }
                        }
                    }
                    if (el.$type === 'camunda:Out') {
                        if (el.variables) {
                            value.outMappingAll = true;
                        }
                        else {
                            if (el.source) {
                                value.OutMappings.push({ type: 'source', source: el.source, target: el.target })
                            }
                            else {
                                value.OutMappings.push({ type: 'sourceExpression', source: el.sourceExpression, target: el.target })
                            }
                        }
                    }

                });
            }
            let inLocalStorege = localStorage.getItem("savedBpmn") ? JSON.parse(localStorage.getItem("savedBpmn")) : [];
            this.setState({
                values: value,
                visible: true,
                processList: inLocalStorege
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null });
        this.props.clearElement()
    }
    showModal = (event) => {
        let inLocalStorege = localStorage.getItem("savedBpmn") ? JSON.parse(localStorage.getItem("savedBpmn")) : [];
        this.setState({
            visible: true,
            event: event,
            processList: inLocalStorege
        });
    }
    method = (value) => {
        this.setState({
            method: value
        })
    }

    createCallActivity = (value) => {
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');
        // Create Call Activity
        const callActivity = elementFactory.createShape({
            type: "bpmn:CallActivity",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
        });
        // Set called element
        callActivity.businessObject.set("calledElement", value.calledElement);
        // Set binding deployment
        callActivity.businessObject.set("calledElementBinding", "deployment");
        if(value.documentation){
            const newDocumentation = bpmnFactory.create('bpmn:Documentation');
            newDocumentation.text = value.documentation;
            if (!Array.isArray(callActivity.businessObject.documentation)) {
                callActivity.businessObject.documentation = [];
            }
            // Add the new documentation to the  call Activity
            callActivity.businessObject.documentation.push(newDocumentation); 
        }

        // Create input/output mapping
        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [],
            outputParameters: [],
        });
        if (value.InputParameters) {
            value.InputParameters.forEach((el) => {
                let input = bpmnFactory.create('camunda:InputParameter', {
                    name: el.name,
                    value: el.value
                });
                inputOutput.inputParameters.push(input)
            })
        }
        if (value.OutputParameters) {
            value.OutputParameters.forEach((el) => {
                let output = bpmnFactory.create('camunda:OutputParameter', {
                    name: el.name,
                    value: el.value
                });
                inputOutput.outputParameters.push(output)
            })
        }
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        let arrInputOutput = [inputOutput];
        if (value.InMappings) {
            value.InMappings.forEach((el) => {
                let mappingObg = { target: el.target }
                el.type === "sourrce" ? mappingObg.source = el.source : mappingObg.sourceExpression = el.source;

                let inMapping = bpmnFactory.create('camunda:In', mappingObg);
                arrInputOutput.push(inMapping)
            })
        }
        if (value.OutMappings) {
            value.OutMappings.forEach((el) => {
                let mappingObg = { target: el.target }
                el.type === "sourrce" ? mappingObg.source = el.source : mappingObg.sourceExpression = el.source;

                let OutMappings = bpmnFactory.create('camunda:Out', mappingObg);
                arrInputOutput.push(OutMappings)
            })
        }
        if (value.inMappingAll) {
            let InMappingAll = bpmnFactory.create('camunda:In', {
                variables: 'all',
            });
            arrInputOutput.push(InMappingAll);
        }
        if (value.outMappingAll) {
            let OutMappingAll = bpmnFactory.create('camunda:Out', {
                variables: 'all',
            });
            arrInputOutput.push(OutMappingAll);
        }
        extensionElements.set("values", arrInputOutput);
        callActivity.businessObject.set("extensionElements", extensionElements);
        // Set name of call activity
        callActivity.businessObject.set("name", value.name);

        if (this.state.values) {
            let newElement = modeling.replaceShape(this.props.activeElement, callActivity);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [callActivity], {
                hints: {
                    autoSelect: [callActivity]
                }
            });
        }

        this.setState({
            visible: false,
            values: null
        });
        // inputOutput.set('camunda:propagateAllVariables', true);
    };



    render() {
        let options = []
        if (this.state.processList.length > 0) {
            this.state.processList.forEach((el, index) => {
                options.push({
                    key: el.id + index,
                    value: el.id,
                    label: <Popover
                        key={el.id + index}
                        content={<MyDiagramViewer data={el.data} formId={"diagram" + index + generateID()} />}
                        title={el.name}
                        trigger="hover"
                        placement="left"
                        style={{ marginRight: '200px' }}
                    >
                        <div>{el.name}</div>
                    </Popover>
                })
            });
        }
        let modal = <Modal title="Create Call ativity" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createCallActivity} autoComplete="off" initialValues={this.state.values}>
                <Form.Item
                    name='name'
                    label='Task name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                        name='documentation'
                        label='Documentation'
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                <Form.Item
                    name='calledElement'
                    label='Process'
                    rules={[
                        {
                            required: true,
                            message: 'Missing Process',
                        },
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}
                        options={options}>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="inMappingAll"
                    valuePropName="checked"
                    label='In mapping propagation'
                >
                    <Checkbox>Propagate all variables</Checkbox>
                </Form.Item>
                <Form.Item
                    name="outMappingAll"
                    valuePropName="checked"
                    label='Out mapping propagation'
                >
                    <Checkbox>Propagate all variables</Checkbox>
                </Form.Item>
                <Collapse collapsible="header" bordered={false} ghost>
                    <Panel header={<h4>Inputs  <PlusOutlined /></h4>} key="1">

                        <Form.List name="InputParameters" >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                marginBottom: 8,
                                            }}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                label="Name variable"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing name',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                label="Value"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing value',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Panel>
                    <Panel header={<h4>Outputs  <PlusOutlined /></h4>} key="2">
                        <Form.List name="OutputParameters" >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                marginBottom: 8,
                                            }}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'name']}
                                                label="Name variable"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing name',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'value']}
                                                label="Value"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing value',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Panel>
                    <Panel header={<h4>In mappings  <PlusOutlined /></h4>} key="3">
                        <Form.List name="InMappings" >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginBottom: 8,
                                            }}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'type']}
                                                initialValue={'source'}
                                                label='Type'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing  type',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    options={[{
                                                        value: 'source',
                                                        label: "Source"
                                                    },
                                                    {
                                                        value: "sourceExpression",
                                                        label: "Source Expression"
                                                    }]}>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'source']}
                                                label="Source"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing source',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'target']}
                                                label="Target"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing target',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Panel>
                    <Panel header={<h4>Out mappings  <PlusOutlined /></h4>} key="4">
                        <Form.List name="OutMappings" >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Space
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                justifyContent: "space-between",
                                                marginBottom: 8,
                                            }}
                                            align="baseline"
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'type']}
                                                initialValue={'source'}
                                                label='Type'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing  type',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    options={[{
                                                        value: 'source',
                                                        label: "Source"
                                                    },
                                                    {
                                                        value: "sourceExpression",
                                                        label: "Source Expression"
                                                    }]}>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'source']}
                                                label="Source"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing source',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'target']}
                                                label="Target"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Missing target',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Panel>
                </Collapse>


                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="rightTop" title="Call Activity">
            <PlusSquareOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}

export default CallActivity;