import React, { Component } from "react";
import { Input, Modal,  Button, Form,Tooltip } from 'antd';
import { FolderViewOutlined, } from '@ant-design/icons';


class EntryIdsCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null,
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let activeElement =this.props.activeElement
        if (activeElement && activeElement.type === 'bpmn:ServiceTask' && !this.state.visible && activeElement.businessObject.delegateExpression === "${entryChildrenIDsCollection}") {//Change
            let value = {};
            let inputParameters = null;
            if(this.props.activeElement.businessObject.extensionElements){
                inputParameters = this.props.activeElement.businessObject.extensionElements.values[0].inputParameters;
            }
           inputParameters.forEach((el)=> value[el.name] = el.value );
            this.setState({
                values: value,
                visible: true
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false ,values:null });
        this.props.clearElement()
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        })
    }

    createTask = (value) => {
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');

        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';
        ServiceTask.modelerTemplate =  "com.aphinit.bpmn.delegate.EntryChildrenIDsCollection";//Change
        ServiceTask.delegateExpression = "${entryChildrenIDsCollection}";//Change

        let parentId = bpmnFactory.create('camunda:InputParameter', {
            name: "entryChildrenIDs_ParentId",
            value: value.entryChildrenIDs_ParentId
        });
        let nameVariable = bpmnFactory.create('camunda:InputParameter', {
            name: "entryChildrenIDs_ResultVariableName",
            value: value.entryChildrenIDs_ResultVariableName
        });

        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [ parentId, nameVariable],
            outputParameters: []
        });
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput]);
        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', "Entry IDs collection");
        serviceTask.businessObject.set("extensionElements", extensionElements);
        if (this.state.values) {
            let newElement =modeling.replaceShape(this.props.activeElement, serviceTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [serviceTask], {
                hints: {
                    autoSelect: [serviceTask]
                }
            });
        }
        this.setState({
            visible: false ,values:null
        });
    };


    render() {
        let modal = <Modal title="Entry IDs collection" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createTask} autoComplete="off" initialValues={this.state.values}>
            <Form.Item
                    name='entryChildrenIDs_ParentId'
                    label='Parent id'
                    help="parent folder id"
                    rules={[
                        {
                            required: true,
                            message: 'Missing Parent id',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='entryChildrenIDs_ResultVariableName'
                    label='Variable name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing variable name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
              
                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="rightTop" title="Entry IDs collection">
       <FolderViewOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
    </Tooltip>
        </>
    }
}

export default EntryIdsCollection;