import React, { Component } from "react";
import { Input, Modal, Select, Button, Form,Tooltip } from 'antd';
import { SolutionOutlined, } from '@ant-design/icons';
import { isArray, } from "../../utils/JsObjectHelper";

class ColectionId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null,
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let activeElement =this.props.activeElement
        if (activeElement && activeElement.type === 'bpmn:ServiceTask' && !this.state.visible && activeElement.businessObject.delegateExpression === "${UserIDsColection}") {
            let value = {};
            let inputParameters = null;
            if(this.props.activeElement.businessObject.extensionElements){
                inputParameters = this.props.activeElement.businessObject.extensionElements.values[0].inputParameters;
            }
           inputParameters.forEach((el)=> value[el.name] = el.value );
           value.groupsId = value.groupsId? value.groupsId.split(","):undefined;
           value.userIds  = value.userIds ? value.userIds.split(","):undefined;
            this.setState({
                values: value,
                visible: true
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false ,values:null });
        this.props.clearElement()
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        })
    }

    createConnector = (value) => {
        let groups = value.groupsId?isArray(value.groupsId)?value.groupsId.join():value.groupsId:'';
        let users = value.userIds?isArray(value.userIds)?value.userIds.join():value.userIds:'';

        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');

        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';
        ServiceTask.modelerTemplate =  "com.aphinit.bpmn.UserIDsCollection";
        ServiceTask.delegateExpression = "${userIDsCollection}";

        let receiverUserIds = bpmnFactory.create('camunda:InputParameter', {
            name: "userIds",
            value: users
        });
        let receiverGroupIds = bpmnFactory.create('camunda:InputParameter', {
            name: "groupsId",
            value: groups
        });
        let nameVariable = bpmnFactory.create('camunda:InputParameter', {
            name: "nameVariable",
            value: value.nameVariable
        });

        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [ receiverUserIds, receiverGroupIds, nameVariable],
            outputParameters: []
        });
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput]);
        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', "Collection id");
        serviceTask.businessObject.set("extensionElements", extensionElements);
        if (this.state.values) {
           let newElement=  modeling.replaceShape(this.props.activeElement, serviceTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [serviceTask], {
                hints: {
                    autoSelect: [serviceTask]
                }
            });
        }
        this.setState({
            visible: false ,values:null
        });
    };


    render() {
        let optionsUser = [];
        let options = [];
        if (isArray(this.props.groupList)) {
            this.props.groupList.forEach((el) => {
                options.push({
                    value: el.id,
                    label: el.name
                })
            })
        }
        if (isArray(this.props.userList)) {
            this.props.userList.forEach((el) => {
                optionsUser.push({
                    value: el.id,
                    label: el.firstName + el.lastName
                })
            })
        }
        let modal = <Modal title="Create colection id" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createConnector} autoComplete="off" initialValues={this.state.values}>
                <Form.Item
                    name='groupsId'
                    label='Recipients groups'
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        options={options}>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='userIds'
                    label='Recipients people'>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        
                        options={optionsUser}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    name='nameVariable'
                    label='Variable name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  variable name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
              
                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="rightTop" title="Collection Id">
       <SolutionOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
    </Tooltip>
        </>
    }
}

export default ColectionId