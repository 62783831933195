import React, { Component } from "react";
import { Tooltip, } from 'antd';
import { MergeCellsOutlined, } from '@ant-design/icons';

class MergeDraft extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    createMergeDraft = (event) => {
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");


        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';
        ServiceTask.modelerTemplate = "com.aphinit.bpmn.MergeDraftToOriginal";
        ServiceTask.delegateExpression = "${mergeEntryDraftDelegate}";

        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: 0,
            y: 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', 'Merge draft to original');
        create.start(event, [serviceTask], {
            hints: {
                autoSelect: [serviceTask]
            }
        });

        this.setState({
            visible: false,
        });
    };


    render() {
        return <><Tooltip placement="rightTop" title="Merge dtraft to original">
            <MergeCellsOutlined onClick={(event) => this.createMergeDraft(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}

export default MergeDraft