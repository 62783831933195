import React, { Component } from "react";
import { Input, Modal, Button, Form, Tooltip, Radio } from 'antd';
import { IdcardOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { isUndefined } from "../../utils/JsObjectHelper";


class GetUserId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null,
            typeIdentity: null
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let activeElement = this.props.activeElement;
        if (activeElement && activeElement.type === 'bpmn:ServiceTask' && !this.state.visible && (activeElement.businessObject.delegateExpression === "${entryRelatedIdentityId}" || activeElement.businessObject.delegateExpression === "${entryRelatedIdentity}")) {
            let value = {};
            let identity = activeElement.businessObject.delegateExpression === "${entryRelatedIdentityId}" ? "entryRelatedIdentityId" : "entryRelatedIdentity";
            value.type = identity;
            let inputParameters = null;
            if (this.props.activeElement.businessObject.extensionElements) {
                inputParameters = this.props.activeElement.businessObject.extensionElements.values[0].inputParameters;
            }
            inputParameters.forEach((el) => value[el.name] = el.value);
            this.setState({
                values: value,
                visible: true,
                typeIdentity: identity
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null });
        this.props.clearElement()
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        });
    }

    createGetUserId = (value) => {
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');

        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';

        ServiceTask.modelerTemplate = value.type === 'entryRelatedIdentityId' ? 'com.aphinit.bpmn.delegate.EntryRelatedIdentityId' : "com.aphinit.bpmn.delegate.EntryRelatedIdentity";
        ServiceTask.delegateExpression = value.type === 'entryRelatedIdentityId' ? '${entryRelatedIdentityId}' : "${entryRelatedIdentity}";

        let entryId = bpmnFactory.create('camunda:InputParameter', {
            name: 'entryRelatedIdentity_EntryId',
            value: value.entryRelatedIdentity_EntryId,
        });
        let nameVariable = bpmnFactory.create('camunda:InputParameter', {
            name: 'entryRelatedIdentity_IdentityVariableName',
            value: value.entryRelatedIdentity_IdentityVariableName,
        });

        if (value.type === 'entryRelatedIdentityId') {
            if(isUndefined(value.entryRelatedIdentityId_EntryId)){
                value.entryRelatedIdentityId_EntryId=value.entryRelatedIdentity_EntryId
                value.entryRelatedIdentityId_IdentityIdVariableName=value.entryRelatedIdentity_IdentityVariableName
            }
            entryId = bpmnFactory.create('camunda:InputParameter', {
                name: 'entryRelatedIdentityId_EntryId',
                value: value.entryRelatedIdentityId_EntryId,
            });
            nameVariable = bpmnFactory.create('camunda:InputParameter', {
                name: 'entryRelatedIdentityId_IdentityIdVariableName',
                value: value.entryRelatedIdentityId_IdentityIdVariableName,
            });
        }
        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [entryId, nameVariable],
            outputParameters: []
        });

        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput]);
        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', value.name);
        serviceTask.businessObject.set("extensionElements", extensionElements);
        if (this.state.values) {
          let newElement =  modeling.replaceShape(this.props.activeElement, serviceTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [serviceTask], {
                hints: {
                    autoSelect: [serviceTask]
                }
            });
        }

        this.setState({
            visible: false, values: null, typeIdentity: null
        });
    };


    render() {
        const { typeIdentity } = this.state;
        console.log(typeIdentity);
        let modal = <Modal title="Get user identity" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createGetUserId} autoComplete="off" initialValues={this.state.values}>
                <Form.Item
                    name='name'
                    label='Task name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={typeIdentity === 'entryRelatedIdentityId' ? 'entryRelatedIdentityId_EntryId' : 'entryRelatedIdentity_EntryId'}
                    label='Entry ID'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  entry id',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="type"
                    label={<div><span style={{ marginRight: '10px' }}>Type</span><Tooltip placement="top" color='white' title={<span style={{ color: 'black' }}>This component is primary designed to use system variables(AP_ENTRY_ID, AP_INITIATOR) so it is not possible to run the process directly from Modeler environment.
                        If you are not using these variables in your process and wish to run the process directly, choose "Modeler run enabled" option. If this is not the case, choose "Aphinit run only" option. </span>}>
                        <InfoCircleOutlined style={{ color: 'silver' }} />
                    </Tooltip></div>}
                    rules={[
                        {
                            required: true,
                            message: 'Please pick an item!',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value="entryRelatedIdentityId">Get id only</Radio>
                        <Radio value="entryRelatedIdentity">Get all parameters</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name={typeIdentity === 'entryRelatedIdentityId' ? 'entryRelatedIdentityId_IdentityIdVariableName' : 'entryRelatedIdentity_IdentityVariableName'}
                    label='Identity - variable name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  email subject',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>



                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="rightTop" title="Get user identity">
            <IdcardOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '20px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}

export default GetUserId